

























































































import { defineComponent, computed } from '@vue/composition-api';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import * as R from 'ramda';
import { useModel } from '../composable';
import DataQuerySummary from './DataQuerySummary.vue';
import FieldTooltip from './FieldTooltip.vue';
import { renamings, S } from '@/app/utilities';

extend('required', required);

export default defineComponent({
    name: 'QuerySummary',
    components: {
        DataQuerySummary,
        FieldTooltip,
    },
    props: {
        dataModels: {
            type: Object,
            required: true,
        },
        selectedFacets: {
            type: Object,
            required: true,
        },
        searchQuery: {
            type: Object,
            required: true,
        },
        dataQuery: {
            type: Object,
            required: true,
        },
        availableSearchFields: {
            type: Array,
            default: () => [],
        },
        facets: {
            type: Array,
            default: () => [],
        },
        conceptsByUid: {
            type: Object,
            required: true,
        },
    },
    setup: (props: any, { emit }) => {
        const { filterConcepts } = useModel();

        const displayedFacets = computed(() => {
            return props.facets.map((facet: { key: string; label: string }) => ({
                label: facet.label,
                values: props.selectedFacets[facet.key].reduce((acc: string[], uid: string) => {
                    if (S.has(uid, props.conceptsByUid)) {
                        acc.push(props.conceptsByUid[uid].name);
                    } else {
                        acc.push(uid);
                    }
                    return acc;
                }, []),
            }));
        });

        const concepts = computed(() =>
            filterConcepts(props.dataModels, (path: string, concept: any) => {
                return props.selectedFacets.fieldsByUid.includes(concept.uid);
            }),
        );
        const domains = computed(() => {
            if (props.selectedFacets.domainsByUid.length > 0) {
                return props.selectedFacets.domainsByUid.join(', ');
            }
            return 'any';
        });

        const types = computed(() => {
            if (props.selectedFacets['distribution.type'].length > 0) {
                return props.selectedFacets['distribution.type'].join(', ');
            }
            return 'any';
        });

        const format = computed(() => {
            if (props.selectedFacets['distribution.format'].length > 0) {
                return props.selectedFacets['distribution.format'].join(', ');
            }
            return 'any';
        });

        const searchField = computed(() => {
            if (!R.isNil(props.searchQuery.field?.fieldId)) {
                for (let i = 0; i < props.availableSearchFields.length; i++) {
                    const field = props.availableSearchFields[i];
                    if (field.id === props.searchQuery.field.fieldId) {
                        return field;
                    }
                }
            }
            return null;
        });

        const editTextQuery = () => {
            emit('edit-text-query');
        };

        return { domains, types, format, concepts, searchField, editTextQuery, displayedFacets, renamings };
    },
});
