


































import { defineComponent, computed } from '@vue/composition-api';
import { FacetTypes } from '../constants';
import { renamings } from '../../../app/utilities';

export default defineComponent({
    name: 'Facet',
    props: {
        facet: {
            type: Object,
            required: true,
        },
        facetKey: {
            type: String,
            required: true,
        },
        selections: {
            type: Array,
            default() {
                return [];
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any, { emit }: { emit: any }) {
        const isSingleselect = computed(() => props.facet.type === FacetTypes.Singleselect);
        const isMultiselect = computed(() => props.facet.type === FacetTypes.Multiselect);

        const onCheck = (event: { currentTarget: any }) => {
            const identifier = event.currentTarget.getAttribute('data-id');
            const index = props.selections.indexOf(identifier);
            const isChecked = index === -1;
            const newSelections = [...props.selections];
            if (isChecked) {
                newSelections.push(identifier);
            } else {
                newSelections.splice(index, 1);
            }
            emit('change', newSelections, props.facetKey);
        };
        return { isSingleselect, isMultiselect, onCheck, renamings };
    },
});
