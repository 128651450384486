var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataset && _vm.selectedForeignDataset)?_c('div',{staticClass:"flex flex-col w-full h-full space-y-4"},[_c('validation-observer',{ref:"conditionForm",staticClass:"flex flex-col justify-between h-full space-y-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-col space-y-6"},[_c('div',{staticClass:"flex flex-col space-y-2"},[_c('label',{staticClass:"block text-xs font-bold tracking-wide uppercase whitespace-nowrap text-neutral-600",attrs:{"for":"combinationDataset"}},[_vm._v(" "+_vm._s(_vm.renamings("Combine With Dataset"))+" ")]),_c('validation-provider',{staticClass:"w-full",attrs:{"rules":"required","name":"dataset"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedForeignDatasetId),expression:"selectedForeignDatasetId"}],staticClass:"block w-full py-1 pl-2 pr-8 text-sm form-select",class:{
                                'border-red-700': errors.length > 0,
                            },attrs:{"id":"combinationDataset"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedForeignDatasetId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.datasets),function(dataset){return _c('option',{key:dataset.id,staticClass:"text-neutral-800",domProps:{"value":dataset.id}},[_vm._v(" "+_vm._s(dataset.name)+" ")])}),0),(errors.length > 0)?_c('input-error-icon',{attrs:{"tooltip":errors[0]}}):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"flex flex-col space-y-2"},[_c('label',{staticClass:"block text-xs font-bold tracking-wide uppercase whitespace-nowrap text-neutral-600",attrs:{"for":"localField"}},[_vm._v(" Combine using "),_c('span',{staticClass:"text-purple-700"},[_vm._v(_vm._s(_vm.dataset.name))]),_vm._v(" "+_vm._s(_vm.renamings("field"))+" ")]),_c('validation-provider',{staticClass:"w-full",attrs:{"rules":"required","name":"localField"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('div',{staticClass:"relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedLocalField),expression:"selectedLocalField"}],staticClass:"block w-full py-1 pl-2 pr-8 text-sm form-select",class:{
                                'border-red-700': errors.length > 0,
                            },attrs:{"id":"localField"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedLocalField=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.renamings("Select a field...")))]),_vm._l((_vm.dataset.schemaFields),function(field){return _c('option',{key:field,staticClass:"text-neutral-800",domProps:{"value":field}},[_vm._v(" "+_vm._s(field)+" ")])})],2),(errors.length > 0)?_c('input-error-icon',{attrs:{"tooltip":errors[0]}}):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"flex flex-col space-y-2"},[_c('label',{staticClass:"block text-xs font-bold tracking-wide uppercase whitespace-nowrap text-neutral-600",attrs:{"for":"localField"}},[_vm._v(" Combine using "),_c('span',{staticClass:"text-purple-700"},[_vm._v(_vm._s(_vm.selectedForeignDataset.name))]),_vm._v(" field ")]),_c('validation-provider',{staticClass:"w-full",attrs:{"rules":"required","name":"localField"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('div',{staticClass:"relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedForeignField),expression:"selectedForeignField"}],staticClass:"block w-full py-1 pl-2 pr-8 text-sm form-select",class:{
                                'border-red-700': errors.length > 0,
                            },attrs:{"id":"localField"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedForeignField=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.renamings("Select a field.."))+".")]),_vm._l((_vm.selectedForeignDataset.schemaFields),function(field){return _c('option',{key:field,staticClass:"text-neutral-800",domProps:{"value":field}},[_vm._v(" "+_vm._s(field)+" ")])})],2),(errors.length > 0)?_c('input-error-icon',{attrs:{"tooltip":errors[0]}}):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"flex flex-col space-y-2"},[_c('label',{staticClass:"block text-xs font-bold tracking-wide uppercase whitespace-nowrap text-neutral-600",attrs:{"for":"overridingDataset"}},[_vm._v(" "+_vm._s(_vm.renamings("Overriding Dataset"))+" ")]),_c('validation-provider',{staticClass:"w-full",attrs:{"rules":"required","name":"overridingDataset"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('div',{staticClass:"relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOverridingDatasetId),expression:"selectedOverridingDatasetId"}],staticClass:"block w-full py-1 pl-2 pr-8 text-sm form-select",class:{
                                'border-red-700': errors.length > 0,
                            },attrs:{"id":"overridingDataset"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedOverridingDatasetId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l(([_vm.dataset, _vm.selectedForeignDataset]),function(datasetObj){return _c('option',{key:datasetObj.name,staticClass:"text-neutral-800",domProps:{"value":datasetObj.id}},[_vm._v(" "+_vm._s(datasetObj.name)+" ")])}),0),(errors.length > 0)?_c('input-error-icon',{attrs:{"tooltip":errors[0]}}):_vm._e()],1)]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-row justify-between"},[_c('div'),_c('div',{staticClass:"flex flex-row space-x-4 text-sm"},[(!_vm.hasData)?_c('div',{staticClass:"px-2 py-1 underline cursor-pointer text-neutral-600",on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]):_c('div',{staticClass:"px-2 py-1 underline cursor-pointer text-neutral-600",on:{"click":_vm.clear}},[_vm._v("Clear")]),_c('button',{staticClass:"px-2 py-1 text-white bg-purple-800 rounded cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed",attrs:{"disabled":invalid},on:{"click":_vm.combine}},[_vm._v(" Combine ")])])])]}}],null,false,1946771502)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }